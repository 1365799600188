import React from "react";

const PortfolioUrl = () => {
    return (
        <>
            <ul class="main-menu" id="top-menu-nav">
                <li class="mega-sub-menu">
                    <span>On-Demand Solution</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-nykaa">
                                    Mobileapp like Nykaa
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/food-delivery-app">
                                        Food Delivery App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/grocery-delivery-app">
                                        Grocery Delivery App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/dating-app">
                                        Grocery Delivery App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/grocery-delivery-app">
                                        Dating App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/home-service-app">
                                        Home Service App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/flowers-and-gifts-delivery-app">
                                        Flowers and Gifts Delivery App
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/petcare-app">
                                        PetCare-App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/taxi-booking-app">
                                        Taxi-Booking-App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/travel-booking-app">
                                        Travel-Booking-App
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/bus-booking-app">
                                        Bus Booking App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/flight-booking-app">
                                        Flight Booking App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/holiday-home-booking-app">
                                        Holiday Home Booking App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/local-service-provider-app">
                                        Local Service Provider App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/liquor-delivery-app-like-liquidz">
                                        Liquor delivery app like living liquidz
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/e-learning-app-like-byju">
                                        E-learning app Like Byjus
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-zomato">
                                    Mobileapp like Zomato - RestaurantHub App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/e-commerce-marketplace-like-flipkart">
                                        E-commerce Marketplace Like Flipkart
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-flipboard">
                                        Mobileapp like Flipboard
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-starbucks">
                                        Mobileapp Like Starbucks
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-oyo">
                                        Mobileapp like Oyo
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-american-bank">
                                        Mobileapp like American Bank App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-soundslice">
                                        Webapp like Soundslice
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-figma">
                                        WebApp like Figma
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>HealthCare & Fitness</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/hospital-management-system">
                                        Hospital Management System Software
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/telehealth-platform">
                                        Tele Health Consultant Platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/fitness-and-nutrition-app">
                                        Fitness and Nutrition App Using AI
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/skin-care-app-using-ai">
                                        Skin Care App Using AI
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/medicine-delivery-app">
                                        Medicine Delivery App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/medical-lab-management-system">
                                        Medical Lab Management System
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/organ-detection-app-using-deep-learning">
                                        Organ Detection App Using Deep Learning
                                        OpenAI and TensorFlow
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/electronic-health-records-management-system">
                                        Electronic Health Records Management
                                        System
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Blockchain</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/pimlico">
                                        Webapp like Pimlico
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/Webapp-like-zerodev">
                                        webapp like ZeroDev
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/Webapp-like-push">
                                        webapp like Push
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/aave">
                                        Webapp like Aave
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/decentrilized-exchange-like-cowswap">
                                        Decentralized exchange like cowswap
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-realestate">
                                    Mobileapp like RealEstate
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/chainlink">
                                        Webapp like chainlink
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-kucoin">
                                    Mobileapp Like Kucoin
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/money-transfer-protocol-like-transak-app">
                                        Money transfer protocol like transak App
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/stackup">
                                        Webapp like Stackup
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-moralis">
                                        Webapp Like Moralis
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/Webapp-like-opensea">
                                        Webapp like Opensea
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-coinmarketcap">
                                        Webapp like Cryptocurrency
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-web3auth">
                                        Webapp Like Web3auth
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/etherscan">
                                        Etherscan app
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-binance">
                                    Mobileapp like Binance
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-alchemy">
                                        Webapp Like ALchemy
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/platform-like-quantstamp">
                                        webapp like Quantstamp
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-metamask">
                                    Mobileapp Like Metamask
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-openzeppelin">
                                        Webapp like OpenZeppelin
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-uniswap">
                                        Webapp Like uniswap
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-kfc">
                                    Mobileapp like KFC
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/mobileapp-like-duolingo">
                                    Mobileapp Like Duolingo
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-soundslice">
                                        Webapp like Soundslice
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/portfolio/webapp-like-figma">
                                        WebApp like Figma
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default PortfolioUrl;
