"use client";
import React from "react";

const MenuItem = ({
    id,
    title,
    Icon1,
    Icon2,
    hideShow,
    HideShow,
    ArrowShow,
    navTextColor,
    pageUrl,
}) => {
    return (
        <>
            <span
                // href={address}
                className={`menuTitle ${navTextColor} flex MenuList !font-manrope`}
                onMouseEnter={(e) => {
                    HideShow(e, id);
                }}
            >
                {title}
                <div className="grid place-items-center ml-0.5">
                    {hideShow && ArrowShow === id ? <Icon1 /> : <Icon2 />}
                </div>
            </span>
            {pageUrl && pageUrl}
        </>
    );
};

export default MenuItem;
