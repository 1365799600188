export const technologiesMainItem = [
    { id: 1, title: "Database" },
    { id: 2, title: "Backend" },
    { id: 3, title: "Mobile" },
    { id: 4, title: "Frontend" },
    { id: 5, title: "UI/UX" },
    // { id: 6, title: "Bi & Analytics" },
    // { id: 7, title: "Cloud" },
    // { id: 8, title: "Trending Tech" },
    // { id: 9, title: "Artificial intelligence" },
];

export const database1 = [
    {
        id: 1,
        image: "/assets/Header/Technologies/Database/Oracle.svg",
        href: "/database/oracle",
        title: "Oracle",
    },
    {
        id: 2,
        image: "/assets/Header/Technologies/Database/MongoDB.svg",
        href: "/database/mongodb",
        title: "MongoDB",
    },
    {
        id: 3,
        image: "/assets/Header/Technologies/Database/NoSQL Database.svg",
        href: "/database/nosql",
        title: "NoSQL Database",
    },
    {
        id: 4,
        image: "/assets/Header/Technologies/Database/MYSQL.svg",
        href: "/database/mysql",
        title: "MYSQL",
    },
    {
        id: 5,
        image: "/assets/Header/Technologies/Database/SQL Lite.svg",
        href: "/database/sqlite",
        title: "SQL Lite",
    },
    {
        id: 6,
        image: "/assets/Header/Technologies/Database/MariaDB.svg",
        href: "/database/mariadb",
        title: "MariaDB",
    },
    {
        id: 7,
        image: "/assets/Header/Technologies/Database/Amazon RDS.svg",
        href: "/database/amazon-rds",
        title: "Amazon RDS",
    },
    {
        id: 8,
        image: "/assets/Header/Technologies/Database/Amazon DynamoDB.svg",
        href: "/database/amazon-dynamodb",
        title: "Amazon DynamoDB",
    },
    {
        id: 9,
        image: "/assets/Header/Technologies/Database/Microsoft Azure SQL Database.svg",
        href: "/database/microsoft-azure-sql",
        title: "Microsoft Azure SQL Database",
    },
];

export const database2 = [
    {
        id: 1,
        image: "/assets/Header/Technologies/Database/ElasticSearch.svg",
        href: "/database/elasticsearch",
        title: "ElasticSearch",
    },
    {
        id: 2,
        image: "/assets/Header/Technologies/Database/OrientDB.svg",
        href: "/database/orientdb",
        title: "OrientDB",
    },
    {
        id: 3,
        image: "/assets/Header/Technologies/Database/PostgreSQL.svg",
        href: "/database/postgresql",
        title: "PostgreSQL",
    },
    {
        id: 4,
        image: "/assets/Header/Technologies/Database/Redis Database.svg",
        href: "/database/redis-database",
        title: "Redis Database",
    },
    {
        id: 5,
        image: "/assets/Header/Technologies/Database/CouchDB.svg",
        href: "/database/couchdb",
        title: "CouchDB",
    },
    {
        id: 6,
        image: "/assets/Header/Technologies/Database/Neo4J.svg",
        href: "/database/neo4j-consulting",
        title: "Neo4J",
    },
    {
        id: 7,
        image: "/assets/Header/Technologies/Database/SQL Server.svg",
        href: "/database/sql-server",
        title: "SQL Server",
    },
    {
        id: 8,
        image: "/assets/Header/Technologies/Database/Dynamodb development services.svg",
        href: "/database/dynamodb",
        title: "Dynamodb development services",
    },
    {
        id: 9,
        image: "/assets/Header/Technologies/Database/Firebase Development.svg",
        href: "/database/firebase",
        title: "Firebase Development",
    },
];

// export const database3 = [
//     {
//         id: 1,
//         image: "/assets/Header/Technologies/Database/Amazon RDS.svg",
//         href: "/database/amazon-rds",
//         title: "Amazon RDS",
//     },
//     {
//         id: 2,
//         image: "/assets/Header/Technologies/Database/Amazon DynamoDB.svg",
//         href: "/database/amazon-dynamodb",
//         title: "Amazon DynamoDB",
//     },
//     {
//         id: 3,
//         image: "/assets/Header/Technologies/Database/Microsoft Azure SQL Database.svg",
//         href: "/database/microsoft-azure-sql",
//         title: "Microsoft Azure SQL Database",
//     },
//     {
//         id: 4,
//         image: "/assets/Header/Technologies/Database/SQL Server.svg",
//         href: "/database/sql-server",
//         title: "SQL Server",
//     },
//     {
//         id: 5,
//         image: "/assets/Header/Technologies/Database/Dynamodb development services.svg",
//         href: "/database/dynamodb",
//         title: "Dynamodb development services",
//     },
//     {
//         id: 6,
//         image: "/assets/Header/Technologies/Database/Firebase Development.svg",
//         href: "/database/firebase",
//         title: "Firebase Development",
//     },
// ];

export const backend1 = [
    {
        id: 1,
        image: "/assets/Header/Technologies/Backend/Laravel.svg",
        href: "/backend/laravel",
        title: "Laravel",
    },
    {
        id: 2,
        image: "/assets/Header/Technologies/Backend/CakePHP.svg",
        href: "/backend/cakephp",
        title: "CakePHP",
    },
    {
        id: 3,
        image: "/assets/Header/Technologies/Backend/CodeIgniter.svg",
        href: "/backend/codeigniter",
        title: "CodeIgniter",
    },
    {
        id: 4,
        image: "/assets/Header/Technologies/Backend/NodeJS.svg",
        href: "/backend/nodejs",
        title: "NodeJS",
    },
    {
        id: 5,
        image: "/assets/Header/Technologies/Backend/Django.svg",
        href: "/backend/django",
        title: "Django",
    },
    {
        id: 6,
        image: "/assets/Header/Technologies/Backend/MeteorJS.svg",
        href: "/backend/meteorjs",
        title: "MeteorJS",
    },
    {
        id: 7,
        image: "/assets/Header/Technologies/Backend/SpringBoot.svg",
        href: "/backend/springboot",
        title: "SpringBoot",
    },
];

export const backend2 = [
    {
        id: 1,
        image: "/assets/Header/Technologies/Backend/Flask.svg",
        href: "/backend/flask",
        title: "Flask",
    },
    {
        id: 2,
        image: "/assets/Header/Technologies/Backend/GoLang.svg",
        href: "/backend/golang",
        title: "GoLang",
    },
    {
        id: 3,
        image: "/assets/Header/Technologies/Backend/Ruby on Rails.svg",
        href: "/backend/ruby-on-rails",
        title: "Ruby on Rails",
    },
    {
        id: 4,
        image: "/assets/Header/Technologies/Backend/NestJS.svg",
        href: "/backend/nestjs",
        title: "NestJS",
    },
    {
        id: 5,
        image: "/assets/Header/Technologies/Backend/ExpressJS.svg",
        href: "/backend/expressjs",
        title: "ExpressJS",
    },
    {
        id: 6,
        image: "/assets/Header/Technologies/Backend/PHP.svg",
        href: "/backend/php",
        title: "PHP",
    },
    {
        id: 7,
        image: "/assets/Header/Technologies/Backend/Scala.svg",
        href: "/backend/scala",
        title: "Scala",
    },
];

// export const backend3 = [
//     {
//         id: 1,
//         image: "/assets/Header/Technologies/Backend/MeteorJS.svg",
//         href: "/backend/meteorjs",
//         title: "MeteorJS",
//     },
//     {
//         id: 2,
//         image: "/assets/Header/Technologies/Backend/SpringBoot.svg",
//         href: "/backend/springboot",
//         title: "SpringBoot",
//     },
//     {
//         id: 3,
//         image: "/assets/Header/Technologies/Backend/PHP.svg",
//         href: "/backend/php",
//         title: "PHP",
//     },
//     {
//         id: 4,
//         image: "/assets/Header/Technologies/Backend/Scala.svg",
//         href: "/backend/scala",
//         title: "Scala",
//     },
// ];

export const mobile1 = [
    {
        id: 1,
        image: "/assets/Header/Technologies/Mobile/Swift.svg",
        href: "/mobile/swift",
        title: "Swift",
    },
    {
        id: 2,
        image: "/assets/Header/Technologies/Mobile/Objective-C.svg",
        href: "/mobile/objective-c",
        title: "Objective-C",
    },
    {
        id: 3,
        image: "/assets/Header/Technologies/Mobile/Kotlin.svg",
        href: "/mobile/kotlin",
        title: "Kotlin",
    },
    {
        id: 4,
        image: "/assets/Header/Technologies/Mobile/Ionic App Development.svg",
        href: "/mobile/ionic-app",
        title: "Ionic App Development",
    },
    {
        id: 5,
        image: "/assets/Header/Technologies/Mobile/Xamarin Development.svg",
        href: "/mobile/xamarin",
        title: "Xamarin Development",
    },
];

// export const mobile2 = [
//   {
//     id: 1,
//     image: "/assets/Header/About/Our-company.svg",
//     href: "/",
//     title: "React Native",
//   },
// ];

// export const mobile3 = [
//   {
//     id: 1,
//     image: "/assets/Header/About/Our-company.svg",
//     href: "/",
//     title: "React Native",
//   },
// ];

export const frontend1 = [
    {
        id: 1,
        image: "/assets/Header/Technologies/Frontend/ReactJS.svg",
        href: "/frontend/reactjs",
        title: "ReactJS",
    },
    {
        id: 2,
        image: "/assets/Header/Technologies/Frontend/AngularJS.svg",
        href: "/frontend/angularjs",
        title: "AngularJS",
    },
    {
        id: 3,
        image: "/assets/Header/Technologies/Frontend/JavaScript.svg",
        href: "/frontend/javascript",
        title: "JavaScript",
    },
    {
        id: 4,
        image: "/assets/Header/Technologies/Frontend/VueJS.svg",
        href: "/frontend/vuejs",
        title: "VueJS",
    },
    {
        id: 5,
        image: "/assets/Header/Technologies/Frontend/Typescript.svg",
        href: "/frontend/typescript",
        title: "Typescript",
    },
    {
        id: 6,
        image: "/assets/Header/Technologies/Frontend/NextJS.svg",
        href: "/frontend/nextjs",
        title: "NextJS",
    },
    {
        id: 7,
        image: "/assets/Header/Technologies/Frontend/GatsbyJS.svg",
        href: "/frontend/gatsbyjs",
        title: "GatsbyJS",
    },
    {
        id: 8,
        image: "/assets/Header/Technologies/Frontend/PaperJS.svg",
        href: "/frontend/paperjs",
        title: "PaperJS",
    },
];

export const frontend2 = [
    {
        id: 1,
        image: "/assets/Header/Technologies/Frontend/ThreeJS.svg",
        href: "/frontend/threejs",
        title: "ThreeJS",
    },
    {
        id: 2,
        image: "/assets/Header/Technologies/Frontend/HTML5.svg",
        href: "/frontend/html",
        title: "HTML5",
    },
    {
        id: 3,
        image: "/assets/Header/Technologies/Frontend/CSS.svg",
        href: "/frontend/css3",
        title: "CSS",
    },
    {
        id: 4,
        image: "/assets/Header/Technologies/Frontend/Tailwind CSS.svg",
        href: "/frontend/tailwind",
        title: "Tailwind CSS",
    },
    {
        id: 5,
        image: "/assets/Header/Technologies/Frontend/BootStrap.svg",
        href: "/frontend/bootstrap",
        title: "BootStrap",
    },
    {
        id: 6,
        image: "/assets/Header/Technologies/Frontend/FabricJS.svg",
        href: "/frontend/fabricjs",
        title: "FabricJS",
    },
    {
        id: 7,
        image: "/assets/Header/Technologies/Frontend/ChartJS.svg",
        href: "/frontend/chartjs",
        title: "ChartJS",
    },
    {
        id: 8,
        image: "/assets/Header/Technologies/Frontend/D3JS.svg",
        href: "/frontend/d3js",
        title: "D3JS",
    },
];

// export const frontend3 = [
//     {
//         id: 1,
//         image: "/assets/Header/Technologies/Frontend/PaperJS.svg",
//         href: "/frontend/paperjs",
//         title: "PaperJS",
//     },
//     {
//         id: 2,
//         image: "/assets/Header/Technologies/Frontend/D3JS.svg",
//         href: "/frontend/d3js",
//         title: "D3JS",
//     },
// ];

export const uxui1 = [
    {
        id: 1,
        image: "/assets/Header/Technologies/UXUI/Adobe Photoshop.svg",
        href: "/ui-ux/adobe-photoshop",
        title: "Adobe Photoshop",
    },
    {
        id: 2,
        image: "/assets/Header/Technologies/UXUI/Figma.svg",
        href: "/ui-ux/figma",
        title: "Figma",
    },
    {
        id: 3,
        image: "/assets/Header/Technologies/UXUI/Adobe XD.svg",
        href: "/ui-ux/adobe-xd",
        title: "Adobe XD",
    },
    {
        id: 4,
        image: "/assets/Header/Technologies/UXUI/Sketch.svg",
        href: "/ui-ux/sketch-design",
        title: "Sketch",
    },
    {
        id: 5,
        image: "/assets/Header/Technologies/UXUI/Invision.svg",
        href: "/ui-ux/invision-tool",
        title: "Invision",
    },
    {
        id: 6,
        image: "/assets/Header/Technologies/UXUI/Adobe After effect.svg",
        href: "/ui-ux/adobe-after-effect",
        title: "Adobe After effect",
    },
];

export const uxui2 = [
    {
        id: 1,
        image: "/assets/Header/Technologies/UXUI/Zeplin.svg",
        href: "/ui-ux/zeplin",
        title: "Zeplin",
    },
    {
        id: 2,
        image: "/assets/Header/Technologies/UXUI/Miro.svg",
        href: "/ui-ux/miro-design-tool",
        title: "Miro",
    },
    {
        id: 3,
        image: "/assets/Header/Technologies/UXUI/Adobe Illustrator.svg",
        href: "/ui-ux/adobe-illustrator",
        title: "Adobe Illustrator",
    },
    {
        id: 4,
        image: "/assets/Header/Technologies/UXUI/Adobe Animate.svg",
        href: "/ui-ux/adobe-animate",
        title: "Adobe Animate",
    },
    {
        id: 5,
        image: "/assets/Header/Technologies/UXUI/Adobe Express.svg",
        href: "/ui-ux/adobe-express",
        title: "Adobe Express",
    },
];

// export const uxui3 = [
//     {
//         id: 1,
//         image: "/assets/Header/Technologies/UXUI/Adobe After effect.svg",
//         href: "/ui-ux/adobe-after-effect",
//         title: "Adobe After effect",
//     },
// ];

export const biAnalytics1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Power BI",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Tableau",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Big Data",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Pentaho BI",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Predictive Analytics",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Datapine",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Sisense",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Yellowfin BI",
    },
];

export const biAnalytics2 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Qlikview",
    },
    // {
    //   id: 2,
    //   image: "/assets/Header/About/Our-company.svg",
    //   href: "/",
    //   title: "SAP BI",
    // },
];

export const biAnalytics3 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "SAP BI",
    },
];

export const cloud1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Azure",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "AWS",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Salesforce",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Google cloud",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Oracle Cloud",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        href: "/database/microsoft-azure-sql-services",
        title: "Microsoft Azure",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "DigitalOcean",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "VMware",
    },
];

export const cloud2 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "IBM",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Citrix Systems",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Microsoft Corporation",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Cisco",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "BMC Software",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "ServerSpace",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Kamatera",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Rackspace",
    },
];

export const cloud3 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Red Hat",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "SAP",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Verizon Cloud",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Navisite",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Dropbox",
    },
];

export const trendingTech1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "IoT",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Machine Learning",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Artificial Intelligence",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "MEAN Stack",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "DevOps",
    },
];

// export const trendingTech2 = [
//   {
//     id: 1,
//     image: "/assets/Header/About/Our-company.svg",
//     href: "/",
//     title: "DevOps",
//   },
// ];

// export const trendingTech3 = [
//   {
//     id: 1,
//     image: "/assets/Header/About/Our-company.svg",
//     href: "/",
//     title: "MEAN Stack",
//   },
// ];

export const ai1 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Natural Language Generation",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Speech Recognition",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Machine Learning Platforms",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Virtual Agents",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Decision Management",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "AI Optimized Hardware",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Deep Learning Platforms",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Biometrics",
    },
];

export const ai2 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Robotic Process Automation",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Text Analytics And NLP",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Cyber Defence",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Content Creation",
    },
    {
        id: 5,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Emotion Recognition",
    },
    {
        id: 6,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "Marketing Automation",
    },
    {
        id: 7,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "AI Application in E-Commerce",
    },
    {
        id: 8,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "AI Application in Education",
    },
];

export const ai3 = [
    {
        id: 1,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "AI Application in Lifestyle",
    },
    {
        id: 2,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "AI Application inNavigation",
    },
    {
        id: 3,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "AI Application inHealthcare",
    },
    {
        id: 4,
        image: "/assets/Header/About/Our-company.svg",
        href: "/",
        title: "AI Application in Agriculture",
    },
    {
        id: 5,
        image: "/assets/Header/About/Face Recognition.svg",
        href: "/",
        title: "Face Recognition",
    },
];
