export const blockchainMainItemMobile = [
    {
        id: 1,
        title: "NFTs",
        nfts: [
            {
                id: 1,
                image: "/assets/Header/Blockchain/NFT/NFT Development Services.svg",
                href: "/nfts/development",
                title: "NFT Development",
            },
            {
                id: 2,
                image: "/assets/Header/Blockchain/NFT/White-Label NFT Marketplace.svg",
                href: "/nfts/white-label-marketplace-development",
                title: "White-Label NFT Marketplace Development",
            },
            {
                id: 3,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace Development.svg",
                href: "/nfts/marketplace-development",
                title: "Marketplace Development",
            },
            {
                id: 4,
                image: "/assets/Header/Blockchain/NFT/Free NFT Consulting.svg",
                href: "/nfts/free-consulting",
                title: "Free Consulting",
            },
            {
                id: 5,
                image: "/assets/Header/Blockchain/NFT/NFT Ecosystem.svg",
                href: "/nfts/ecosystem",
                title: "NFT Ecosystem",
            },
            {
                id: 6,
                image: "/assets/Header/Blockchain/NFT/Whitelabel Multi chain NFT.svg",
                href: "/nfts/white-label-multi-chain-marketplace-development",
                title: "Whitelabel Multi chain NFT Marketplace development",
            },
            {
                id: 7,
                image: "/assets/Header/Blockchain/NFT/White Label NFT Launchpad.svg",
                href: "/nfts/white-label-launchpad-development",
                title: "White Label NFT Launchpad Development",
            },
            {
                id: 8,
                image: "/assets/Header/Blockchain/NFT/NFT Launchpad Development.svg",
                href: "/nfts/launchpad-development",
                title: "Launchpad Development",
            },
            {
                id: 9,
                image: "/assets/Header/Blockchain/NFT/Cross-Chain NFT Marketplace.svg",
                href: "/nfts/cross-chain-marketplace-development",
                title: "Cross-Chain NFT Marketplace development",
            },
            {
                id: 10,
                image: "/assets/Header/Blockchain/NFT/NFT Exchange Development.svg",
                href: "/nfts/exchange-development",
                title: "Exchange Development",
            },
            {
                id: 11,
                image: "/assets/Header/Blockchain/NFT/NFT Wallet Development.svg",
                href: "/nfts/wallet-development",
                title: "Wallet Development",
            },
            {
                id: 12,
                image: "/assets/Header/Blockchain/NFT/NFT minting platform development.svg",
                href: "/nfts/minting-platform-development",
                title: "Minting Platform Development",
            },
            {
                id: 13,
                image: "/assets/Header/Blockchain/NFT/NFT Token Development.svg",
                href: "/nfts/non-fungible-token-development",
                title: "Non Fungible Token Development",
            },
            {
                id: 14,
                image: "/assets/Header/Blockchain/NFT/DAO Enabled NFT Platform.svg",
                href: "/nfts/dao-enabled-platform-development",
                title: "DAO Enabled  Platform Development",
            },
            {
                id: 15,
                image: "/assets/Header/Blockchain/NFT/NFT marketplace with Governance.svg",
                href: "/nfts/marketplace-with-governance-token-development",
                title: "Marketplace with Governance Token Development",
            },
            {
                id: 16,
                image: "/assets/Header/Blockchain/NFT/Celebrity NFT Marketplace.svg",
                href: "/nfts/celebrity-nft-marketplace-development",
                title: "Celebrity NFT Marketplace Development",
            },
            {
                id: 17,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace for Music.svg",
                href: "/nfts/music-nft-marketplace-development",
                title: "Music NFT Marketplace Development",
            },
            {
                id: 18,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace for Art.svg",
                href: "/nfts/nft-art-marketplace-development",
                title: "NFT Art Marketplace Development",
            },
            {
                id: 19,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace for Sports.svg",
                href: "/nfts/sports-nft-marketplace-development",
                title: "Sports NFT Marketplace Development",
            },
            {
                id: 20,
                image: "/assets/Header/Blockchain/NFT/NFT for Physical assets.svg",
                href: "/nfts/physical-assets-marketplace-development",
                title: "Physical Assets Marketplace Development",
            },
            {
                id: 21,
                image: "/assets/Header/Blockchain/NFT/NFT Ticketing Marketplace.svg",
                href: "/nfts/ticketing-marketplace-development",
                title: "Ticketing Marketplace Development",
            },
            {
                id: 22,
                image: "/assets/Header/Blockchain/NFT/SFT Development.svg",
                href: "/nfts/sft-development",
                title: "SFT Development",
            },
            {
                id: 23,
                image: "/assets/Header/Blockchain/NFT/NFT Studio.svg",
                href: "/nfts/nft-studio",
                title: "NFT Studio",
            },
            {
                id: 24,
                image: "/assets/Header/Blockchain/NFT/NFT Aggregator Marketplace.svg",
                href: "/nfts/aggregator-marketplace-development",
                title: "Aggregator Marketplace Development",
            },
            {
                id: 25,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Ethereum.svg",
                href: "/nfts/ethereum-nft-marketplace-development",
                title: "Ethereum NFT Marketplace Development",
            },
            {
                id: 26,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Polygon.svg",
                href: "/nfts/polygon-nft-marketplace-development",
                title: "Polygon NFT Marketplace Development",
            },
            {
                id: 27,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace on BSC.svg",
                href: "/nfts/bsc-nft-marketplace-development",
                title: "BSC NFT MArketplace Development",
            },
            {
                id: 28,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Avalanche.svg",
                href: "/nfts/avalanche-nft-marketplace-development",
                title: "Avalanche NFT Marketplace Development",
            },
            {
                id: 29,
                image: "/assets/Header/Blockchain/NFT/NFT Development on Sidechain.svg",
                href: "/nfts/sidechain-nft-marketplace-development",
                title: "Sidechain NFT Marketplace Development",
            },
            {
                id: 30,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Solana.svg",
                href: "/nfts/solana-nft-marketplace-development",
                title: "Solana NFT Marketplace Development",
            },
            {
                id: 31,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Harmony.svg",
                href: "/nfts/harmony-nft-marketplace-development",
                title: "Harmony NFT Marketplace Development",
            },
            {
                id: 32,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace like SuperRare.svg",
                href: "/nfts/superrare-like-nft-marketplace-development ",
                title: "Superrare Like NFT Marketplace Development",
            },
            {
                id: 33,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace like Sudoswap.svg",
                href: "/nfts/sudoswap-like-nft-marketplace-development",
                title: "Sudoswap Like NFT Marketplace Development ",
            },
            {
                id: 34,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace like Sorare.svg",
                href: "/nfts/sorare-like-nft-marketplace-development",
                title: "sorare Like NFT Marketplace Development",
            },
            {
                id: 35,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace like OpenSea.svg",
                href: "/nfts/opensea-like-nft-marketplace-development",
                title: "Opensea Like NFT Marketpalce Development",
            },
            {
                id: 36,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace like Solsea.svg",
                href: "/nfts/solsea-like-nft-marketplace-development",
                title: "Solsea Like NFT Marketplace Development",
            },
            {
                id: 37,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace like Rarible.svg",
                href: "/nfts/rarible-like-nft-marketplace-development",
                title: "Rarible Like NFT Marketplace Development",
            },
            {
                id: 38,
                image: "/assets/Header/Blockchain/NFT/NFT Layer 2 Development.svg",
                href: "/nfts/layer-2-development",
                title: "Layer 2 Development",
            },
            {
                id: 39,
                image: "/assets/Header/Blockchain/NFT/Phygital NFT Marketplace.svg",
                href: "/nfts/phygital-marketplace-development",
                title: "Phygital Marketplace Development",
            },
            {
                id: 40,
                image: "/assets/Header/Blockchain/NFT/P2E NFT Game Development.svg",
                href: "/nfts/p2e-game-development",
                title: "P2E Game Development",
            },
            {
                id: 41,
                image: "/assets/Header/Blockchain/NFT/NFT Marketplace for Real estate.svg",
                href: "/nfts/nft-real-estate-marketplace-development",
                title: "NFT Real Estate Marketplace Development",
            },
            {
                id: 42,
                image: "/assets/Header/Blockchain/NFT/NFT in E-commerce.svg",
                href: "/nfts/nft-in-ecommerce",
                title: "NFT in E-commerce",
            },
            {
                id: 43,
                image: "/assets/Header/Blockchain/NFT/NFT Gaming Platform.svg",
                href: "/nfts/gaming-platform-development",
                title: "Gaming Platform Development",
            },
            {
                id: 44,
                image: "/assets/Header/Blockchain/NFT/DAO Enabled NFT Platform.svg",
                href: "/nfts/dungeon-swap-like-nft-game-development",
                title: "Dungeon Swap Like NFT Game Development",
            },
            {
                id: 45,
                image: "/assets/Header/Blockchain/NFT/My Crypto Heros Like NFT Game.svg",
                href: "/nfts/my-crypto-heroes-like-nft-game-development",
                title: "My Crypto Heros Like NFT Game Development",
            },
            {
                id: 46,
                image: "/assets/Header/Blockchain/NFT/Zed Run Like NFT Game.svg",
                href: "/nfts/zed-run-like-nft-game-development",
                title: "Zed Run Like NFT Game Development",
            },
            {
                id: 47,
                image: "/assets/Header/Blockchain/NFT/Decentraland Like NFT Game.svg",
                href: "/nfts/decentraland-like-nft-game-development",
                title: "Decentraland Like NFT Game Development",
            },
            {
                id: 48,
                image: "/assets/Header/Blockchain/NFT/NFT Racing Game.svg",
                href: "/nfts/nft-racing-game-development",
                title: "NFT Racing Game Development",
            },
            {
                id: 49,
                image: "/assets/Header/Blockchain/NFT/Revv Racing Like NFT Game.svg",
                href: "/nfts/revv-racing-like-nft-game-development",
                title: "Revv Racing Like NFT Game Development",
            },
            {
                id: 50,
                image: "/assets/Header/Blockchain/NFT/NFT Casino Game.svg",
                href: "/nfts/casino-game-development",
                title: "Casino Game Development",
            },
            {
                id: 51,
                image: "/assets/Header/Blockchain/NFT/Social Token Development.svg",
                href: "/nfts/nft-social-token-development",
                title: "NFT Social Token Development",
            },
            {
                id: 52,
                image: "/assets/Header/Blockchain/NFT/NFT Staking platform.svg",
                href: "/nfts/staking-platform-development",
                title: "Staking platform Development",
            },
            {
                id: 53,
                image: "/assets/Header/Blockchain/NFT/NFT Streaming Platform.svg",
                href: "/nfts/streaming-platform-development",
                title: "Streaming Platform Development",
            },
            {
                id: 54,
                image: "/assets/Header/Blockchain/NFT/NFT Consulting Service.svg",
                href: "/nfts/consulting-service",
                title: "Consulting Service",
            },
            {
                id: 55,
                image: "/assets/Header/Blockchain/NFT/NFT Listing Services.svg",
                href: "/nfts/listing-services",
                title: "Listing Services",
            },
            {
                id: 56,
                image: "/assets/Header/Blockchain/NFT/Fractional NFT Marketplace.svg",
                href: "/nfts/fractional-marketplace-development ",
                title: "Fractional NFT Marketplace Development",
            },
            {
                id: 57,
                image: "/assets/Header/Blockchain/NFT/Shards NFT Platform.svg",
                href: "/nfts/shards-nft-platform-development",
                title: "Shards NFT Platform Development",
            },
            {
                id: 58,
                image: "/assets/Header/Blockchain/NFT/White Label P2E NFT Games.svg",
                href: "/nfts/white-label-p2e-game-development",
                title: "White Label P2E NFT Game Development",
            },
            {
                id: 59,
                image: "/assets/Header/Blockchain/NFT/Web2 to Web3 Game.svg",
                href: "/nfts/web2-to-web3-game-development",
                title: "Web2 to Web3 Game Development",
            },
            {
                id: 60,
                image: "/assets/Header/Blockchain/NFT/NFT Game Marketplace.svg",
                href: "/nfts/gaming-marketplace-development",
                title: "Gaming Marketplace Development",
            },
            {
                id: 61,
                image: "/assets/Header/Blockchain/NFT/Move To Earn NFT Platform.svg",
                href: "/nfts/move-to-earn-nft-platform-development",
                title: "Move To Earn NFT Platform Development",
            },
        ],
    },
    {
        id: 2,
        title: "Marketing",
        marketing: [
            {
                id: 1,
                image: "/assets/Header/Blockchain/Marketing/NFT Marketing Services.svg",
                href: "/marketing/nft",
                title: "NFT",
            },
            {
                id: 2,
                image: "/assets/Header/Blockchain/Marketing/NFT Marketplace Marketing Services.svg",
                href: "/marketing/nft-marketplace",
                title: "NFT Marketplace",
            },
            {
                id: 3,
                image: "/assets/Header/Blockchain/Marketing/NFT Collectibles Marketing.svg",
                href: "/marketing/nft-collectibles",
                title: "NFT Collectibles",
            },
            {
                id: 4,
                image: "/assets/Header/Blockchain/Marketing/NFT Game Marketing.svg",
                href: "/marketing/nft-game",
                title: "NFT Game",
            },
            {
                id: 5,
                image: "/assets/Header/Blockchain/Marketing/NFT Telegram Marketing.svg",
                href: "/marketing/nft-telegram",
                title: "NFT Telegram",
            },
            {
                id: 6,
                image: "/assets/Header/Blockchain/Marketing/Influencer Marketing Agency.svg",
                href: "/marketing/influencer",
                title: "Influencer",
            },
            {
                id: 7,
                image: "/assets/Header/Blockchain/Marketing/NFT PRMedia Marketing.svg",
                href: "/marketing/nft-pr-and-media",
                title: "NFT PR and Media Marketing",
            },

            {
                id: 8,
                image: "/assets/Header/Blockchain/Marketing/Discord Marketing.svg",
                href: "/marketing/discord",
                title: "Discord Marketing",
            },
            {
                id: 9,
                image: "/assets/Header/Blockchain/Marketing/Discord Server Marketing.svg",
                href: "/marketing/discord-server-marketing",
                title: "Discord Server Marketing",
            },
            {
                id: 10,
                image: "/assets/Header/Blockchain/Marketing/Discord Brand Marketing.svg",
                href: "/marketing/discord-brand",
                title: "Discord Brand Marketing",
            },
            {
                id: 11,
                image: "/assets/Header/Blockchain/Marketing/Discord Game Marketing.svg",
                href: "/marketing/discord-game",
                title: "Discord Game Marketing",
            },
        ],
    },
    {
        id: 3,
        title: "Metaverse",
        metaverse: [
            {
                id: 1,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse Avatar Developments.svg",
                href: "/metaverse/metaverse-development-company",
                title: "Metaverse Development Company",
            },
            {
                id: 2,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse NFT Marketplace Development.svg",
                href: "/metaverse/metaverse-nft-marketplace-development",
                title: "Metaverse NFT Marketplace Development",
            },
            {
                id: 3,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse Healthcare Solutions.svg",
                href: "/metaverse/healthcare-solutions-development",
                title: "Healthcare Solutions Development Company",
            },
            {
                id: 4,
                image: "/assets/Header/Blockchain/Metaverse/NFT Metaverse Launchpad Development.svg",
                href: "/metaverse/nft-launchpad-development",
                title: "NFT Launchpad Development",
            },
            {
                id: 5,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse NFT Game Development.svg",
                href: "/metaverse/nft-game-development",
                title: "NFT Game Development",
            },
            {
                id: 6,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse Art Gallery.svg",
                href: "/metaverse/art-gallery-development",
                title: "Art Gallery Development",
            },
            {
                id: 7,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse Avatar Development.svg",
                href: "/metaverse/avatar-development",
                title: "Avatar Development Company",
            },
            {
                id: 8,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse 3D Space Development.svg",
                href: "/metaverse/3d-space-development",
                title: "Metaverse 3D Space Development",
            },
            {
                id: 9,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse Event Platform.svg",
                href: "/metaverse/event-platform-development",
                title: "Event Platform Development",
            },
            {
                id: 10,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse Platform for Social Interaction.svg",
                href: "/metaverse/social-interaction-platform-development",
                title: "Social Interaction Development",
            },
            {
                id: 11,
                image: "/assets/Header/Blockchain/Metaverse/Metaverse Virtual Working Space.svg",
                href: "/metaverse/virtual-working-space-development",
                title: "Virtual Working Space Development",
            },
        ],
    },
    {
        id: 4,
        title: "Web 3.0",
        web3: [
            {
                id: 1,
                image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 Development.svg",
                href: "/web3/web3-development-services",
                title: "Web3 Development Services",
            },
            {
                id: 2,
                image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 dApp Development.svg",
                href: "/web3/dapps-development-services",
                title: "dApps Development Services",
            },
            {
                id: 3,
                image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 Wallet Development.svg",
                href: "/web3/wallet-development",
                title: "Web3 Wallet Development",
            },
            {
                id: 4,
                image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 Game Development.svg",
                href: "/web3/game-development",
                title: "Game Development",
            },
            {
                id: 5,
                image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 Marketing Services.svg",
                href: "/web3/marketing-services",
                title: "Marketing Services",
            },
            {
                id: 6,
                image: "/assets/Header/Blockchain/Web 3.0/Metamask like Wallet Development.svg",
                href: "/web3/metamask-like-wallet-development",
                title: "Metamask Like Wallet Development",
            },
        ],
    },
    {
        id: 5,
        title: "DeFi",
        defi: [
            {
                id: 1,
                image: "/assets/Header/Blockchain/DeFi/Decentralized Finance (DeFi) Development.svg",
                href: "/defi/defi-development-services",
                title: "Decentralized Finance (DeFi) Development",
            },
            {
                id: 2,
                image: "/assets/Header/Blockchain/DeFi/DeFi Platform like Pancakeswap.svg",
                href: "/defi/pancakeswap-like-platform-development",
                title: "DeFi Platform like Pancakeswap",
            },
            {
                id: 3,
                image: "/assets/Header/Blockchain/DeFi/DAO Services.svg",
                href: "/defi/dao-development",
                title: "DeFi DAO Services",
            },
            {
                id: 4,
                image: "/assets/Header/Blockchain/DeFi/Whitelabel Swap Exchange Development.svg",
                href: "/defi/white-label-swap-exchange-development",
                title: "Whitelabel Swap Exchange Development",
            },
            {
                id: 5,
                image: "/assets/Header/Blockchain/DeFi/Pinksale Like Launchpad Development.svg",
                href: "/defi/pinksale-like-launchpad-development",
                title: "Pinksale Like Launchpad Development",
            },
            {
                id: 6,
                image: "/assets/Header/Blockchain/DeFi/DeFi Staking Platform Development.svg",
                href: "/defi/staking-platform-development",
                title: "DeFi Staking Platform Development",
            },
            {
                id: 7,
                image: "/assets/Header/Blockchain/DeFi/DeFi Yield Farming Development.svg",
                href: "/defi/yield-farming-development",
                title: "DeFi Yield Farming Development",
            },
            {
                id: 8,
                image: "/assets/Header/Blockchain/DeFi/DeFi Marketing Services.svg",
                href: "/defi/marketing-services",
                title: "DeFi Marketing Services",
            },
            {
                id: 9,
                image: "/assets/Header/Blockchain/DeFi/DeFi Application Development Services.svg",
                href: "/defi/application-development",
                title: "DeFi Application Development Services",
            },
            {
                id: 10,
                image: "/assets/Header/Blockchain/DeFi/DeFi Token Development Services.svg",
                href: "/defi/token-development",
                title: "DeFi Token Development Services",
            },
            {
                id: 11,
                image: "/assets/Header/Blockchain/DeFi/DeFi Protocol Development like yearn finance.svg",
                href: "/defi/protocol-like-yearn-finance-development",
                title: "DeFi Protocol Development like yearn finance",
            },
            {
                id: 12,
                image: "/assets/Header/Blockchain/DeFi/DeFi Wallet Development Solution.svg",
                href: "/defi/wallet-development",
                title: "DeFi Wallet Development Solution",
            },
            {
                id: 13,
                image: "/assets/Header/Blockchain/DeFi/DeFi Synthetic Assets Development.svg",
                href: "/defi/synthetic-assets-development",
                title: "DeFi Synthetic Assets Development",
            },
            {
                id: 14,
                image: "/assets/Header/Blockchain/DeFi/DeFi crowdfunding platform.svg",
                href: "/defi/crowd-funding-platform-development",
                title: "DeFi crowdfunding platform",
            },
            {
                id: 15,
                image: "/assets/Header/Blockchain/DeFi/DeFi Real estate platform.svg",
                href: "/defi/real-estate-platform-development",
                title: "DeFi Real estate platform",
            },
            {
                id: 16,
                image: "/assets/Header/Blockchain/DeFi/DeFi Exchange Development Solution.svg",
                href: "/defi/dex-development",
                title: "DeFi Exchange Development Solution",
            },
            {
                id: 17,
                image: "/assets/Header/Blockchain/DeFi/DeFi Platform Like Uniswap.svg",
                href: "/defi/uniswap-like-platform-development",
                title: "DeFi Platform Like Uniswap",
            },
        ],
    },
    {
        id: 6,
        title: "ICO/STO/IDO",
        icoStoIdo: [
            {
                id: 1,
                image: "/assets/Header/Blockchain/ICO STO IDO/ICO Development.svg",
                href: "/ico/ico-development",
                title: "Development",
            },
            {
                id: 2,
                image: "/assets/Header/Blockchain/ICO STO IDO/ICO Marketing.svg",
                href: "/ico/marketing",
                title: "Marketing",
            },
            {
                id: 3,
                image: "/assets/Header/Blockchain/ICO STO IDO/ICO Community Marketing.svg",
                href: "/ico/community-marketing",
                title: "Community Marketing",
            },
            {
                id: 4,
                image: "/assets/Header/Blockchain/ICO STO IDO/ICO Telegram Marketing.svg",
                href: "ico/telegram-marketing",
                title: "Telegram Marketing",
            },
            {
                id: 5,
                image: "/assets/Header/Blockchain/ICO STO IDO/ICO Influencer Marketing.svg",
                href: "/ico/influencer-marketing",
                title: "Influencer Marketing",
            },
            {
                id: 6,
                image: "/assets/Header/Blockchain/ICO STO IDO/IEO Development.svg",
                href: "/ico/ieo-development",
                title: "IEO Development",
            },
            {
                id: 7,
                image: "/assets/Header/Blockchain/ICO STO IDO/ETO Development.svg",
                href: "/ico/eto-development",
                title: "ETO Development",
            },
            {
                id: 8,
                image: "/assets/Header/Blockchain/ICO STO IDO/Crowdfunding Platform.svg",
                href: "/ico/crowdfunding-platform",
                title: "Crowdfunding Platform",
            },
            {
                id: 9,
                image: "/assets/Header/Blockchain/ICO STO IDO/ICO Youtube Influencer.svg",
                href: "/ico/youtube-influencer",
                title: "Youtube Influencer",
            },
            {
                id: 10,
                image: "/assets/Header/Blockchain/ICO STO IDO/STO Development.svg",
                href: "/sto/development",
                title: "STO Development",
            },
            {
                id: 11,
                image: "/assets/Header/Blockchain/ICO STO IDO/STO Marketing.svg",
                href: "/sto/marketing",
                title: "STO Marketing",
            },
            {
                id: 12,
                image: "/assets/Header/Blockchain/ICO STO IDO/STO Smart Contracts.svg",
                href: "/sto/smart-contract-development",
                title: "Smart Contract Development",
            },
            {
                id: 13,
                image: "/assets/Header/Blockchain/ICO STO IDO/Real Estate Tokenization.svg",
                href: "/sto/real-estate-tokenization",
                title: "Real Estate Tokenization",
            },
            {
                id: 14,
                image: "/assets/Header/Blockchain/ICO STO IDO/Asset Tokenization.svg",
                href: "/sto/asset-tokenization",
                title: "Asset Tokenization",
            },
            {
                id: 15,
                image: "/assets/Header/Blockchain/ICO STO IDO/Art Tokenization.svg",
                href: "/sto/art-tokenization",
                title: "Art Tokenization",
            },
            {
                id: 16,
                image: "/assets/Header/Blockchain/ICO STO IDO/Tokenization Platform.svg",
                href: "/sto/tokenization-platform-development",
                title: "Tokenization Platform  Development",
            },
            {
                id: 17,
                image: "/assets/Header/Blockchain/ICO STO IDO/IDO Development.svg",
                href: "/ido/development",
                title: "IDO Development",
            },
            {
                id: 18,
                image: "/assets/Header/Blockchain/ICO STO IDO/IDO Marketing.svg",
                href: "/ido/marketing",
                title: "IDO Marketing",
            },
            {
                id: 19,
                image: "/assets/Header/Blockchain/ICO STO IDO/White Label IDO Launchpad.svg",
                href: "/ido/white-label-ido-launchpad",
                title: "White Label IDO Launchpad",
            },
            {
                id: 20,
                image: "/assets/Header/Blockchain/ICO STO IDO/IDO Token Launchpad.svg",
                href: "/ido/ido-token-launchpad-development",
                title: "IDO Token Launchpad Development",
            },
            {
                id: 21,
                image: "/assets/Header/Blockchain/ICO STO IDO/IDO Launchpad On BSC.svg",
                href: "/ido/ido-launchpad-development-on-bsc",
                title: "IDO Launchpad Development On BSC",
            },
            {
                id: 22,
                image: "/assets/Header/Blockchain/ICO STO IDO/IDO Launchpad On Ethereum.svg",
                href: "/ido/ido-launchpad-development-on-ethereum",
                title: "IDO Launchpad Development On Ethereum",
            },
            {
                id: 23,
                image: "/assets/Header/Blockchain/ICO STO IDO/IDO Launchpad In Multichain.svg",
                href: "/ido/ido-launchpad-development-on-multichain",
                title: "IDO Launchpad Development On Multichain",
            },
            {
                id: 24,
                image: "/assets/Header/Blockchain/ICO STO IDO/IDO Launchpad On Polygon.svg",
                href: "/ido/ido-launchpad-development-on-polygon",
                title: "IDO Launchpad Development On Polygon",
            },
            {
                id: 25,
                image: "/assets/Header/Blockchain/ICO STO IDO/IGO Launchpad Development.svg",
                href: "/ido/igo-launchpad-development",
                title: "IGO Launchpad Development",
            },
        ],
    },
    {
        id: 7,
        title: "Crypto",
        crypto: [
            {
                id: 1,
                image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Development.svg",
                href: "/crypto/cryptocurrency-development",
                title: "Cryptocurrency Development",
            },
            {
                id: 2,
                image: "/assets/Header/Blockchain/Crypto/Stable Coin Development.svg",
                href: "/crypto/stable-coin-development",
                title: "Stable Coin Development",
            },
            {
                id: 3,
                image: "/assets/Header/Blockchain/Crypto/White label Crypto Exchange.svg",
                href: "/crypto/white-label-crypto-exchange",
                title: "White label Crypto Exchange",
            },
            {
                id: 4,
                image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Exchange Development.svg",
                href: "/crypto/cryptocurrency-exchange-development",
                title: "Cryptocurrency Exchange Development",
            },
            {
                id: 5,
                image: "/assets/Header/Blockchain/Crypto/Decentralized Exchange Development.svg",
                href: "/crypto/decentralized-exchange-development",
                title: "Decentralized Exchange Development",
            },
            {
                id: 6,
                image: "/assets/Header/Blockchain/Crypto/P2P Exchange Development.svg",
                href: "/crypto/p2p-exchange-development",
                title: "P2P Exchange Development",
            },
            {
                id: 7,
                image: "/assets/Header/Blockchain/Crypto/Wallet Development.svg",
                href: "/crypto/crypto-wallet-development",
                title: "Crypto Wallet Development",
            },
            {
                id: 8,
                image: "/assets/Header/Blockchain/Crypto/Crypto-Payment Development.svg",
                href: "/crypto/crypto-payment-development",
                title: "Crypto-Payment Development",
            },
            {
                id: 9,
                image: "/assets/Header/Blockchain/Crypto/Exchange Listing Services.svg",
                href: "/crypto/crypto-exchange-listing-services",
                title: "Crypto Exchange Listing Services",
            },
            {
                id: 10,
                image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Exchange Like Wazirx.svg",
                href: "/crypto/cryptocurrency-exchange-like-wazirx",
                title: "Cryptocurrency Exchange Like Wazirx",
            },
            {
                id: 11,
                image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Exchange Like Binance.svg",
                href: "/crypto/cryptocurrency-exchange-like-binance",
                title: "Cryptocurrency Exchange Like Binance",
            },
            {
                id: 12,
                image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Exchange Like Coinbase.svg",
                href: "/crypto/cryptocurrency-exchange-like-coinbase",
                title: "Cryptocurrency Exchange Like Coinbase",
            },
        ],
    },
    {
        id: 8,
        title: "Blockchain",
        blockchain: [
            {
                id: 1,
                image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain Development.svg",
                href: "/blockchain/blockchain-development-services",
                title: "Blockchain Development",
            },
            {
                id: 2,
                image: "/assets/Header/Blockchain/Blockchain Sub/White Label Blockchain Solutions.svg",
                href: "/blockchain/white-lable-blockchain-development-services",
                title: "White Label Blockchain Solutions",
            },
            {
                id: 3,
                image: "/assets/Header/Blockchain/Blockchain Sub/Custom Blockchain On Avalanche.svg",
                href: "/blockchain/custom-blockchain-development-on-avalanche",
                title: "Custom Blockchain On Avalanche",
            },
            {
                id: 4,
                image: "/assets/Header/Blockchain/Blockchain Sub/Custom Blockchain On Polygon.svg",
                href: "/blockchain/custom-blockchain-development-on-polygon",
                title: "Custom Blockchain On Polygon",
            },
            {
                id: 5,
                image: "/assets/Header/Blockchain/Blockchain Sub/Solana Blockchain Development.svg",
                href: "/blockchain/solana-blockchain-development",
                title: "Solana Blockchain Development",
            },
            {
                id: 6,
                image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain  Web3.0 Consulting.svg",
                href: "/blockchain/blockchain-and-web3-consulting",
                title: "Blockchain and Web 3.0 Consulting",
            },
            {
                id: 7,
                image: "/assets/Header/Blockchain/Blockchain Sub/Proof Of Reserve.svg",
                href: "/blockchain/blockchain-proof-of-reserve",
                title: "Blockchain Proof Of Reserve",
            },
            {
                id: 8,
                image: "/assets/Header/Blockchain/Blockchain Sub/Carbon Credits Development.svg",
                href: "/blockchain/carbon-credits-development",
                title: "Carbon Credits Development",
            },
            ,
            {
                id: 9,
                image: "/assets/Header/Blockchain/Blockchain Sub/DApps Development.svg",
                href: "/blockchain/blockchain-dapps-development",
                title: "Blockchain dApps Development",
            },
            {
                id: 10,
                image: "/assets/Header/Blockchain/Blockchain Sub/P2P Lending Software.svg",
                href: "/blockchain/blockchain-p2p-lending-software",
                title: "Blockchain P2P Lending Software",
            },
            {
                id: 11,
                image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain for Finance.svg",
                href: "/blockchain/blockchain-for-finance",
                title: "Blockchain for Finance",
            },
            {
                id: 12,
                image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain for Pharma Industry.svg",
                href: "/blockchain/blockchain-for-pharma-industry",
                title: "Blockchain for Pharma Industry",
            },
            {
                id: 13,
                image: "/assets/Header/Blockchain/Blockchain Sub/Protocol Implementation.svg",
                href: "/blockchain/blockchain-protocol-implementation",
                title: "Blockchain Protocol Implementation",
            },
            {
                id: 14,
                image: "/assets/Header/Blockchain/Blockchain Sub/EVM Compatible Blockchain and Service.svg",
                href: "/blockchain/evm-compatible-blockchain-and-service",
                title: "EVM Compatible Blockchain and Service",
            },
            {
                id: 15,
                image: "/assets/Header/Blockchain/Blockchain Sub/Smart Contract Development.svg",
                href: "/blockchain/smart-contract-development",
                title: "Smart Contract Development",
            },
        ],
    },
];
