import React from "react";

const BlockchainUrl = () => {
    return (
        <>
            <ul class="main-menu" id="top-menu-nav">
                <li class="mega-sub-menu">
                    <span>NFTs</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/development">
                                        NFT Development Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/white-label-marketplace-development">
                                        White-Label NFT Marketplace
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/marketplace-development">
                                        NFT Marketplace Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/free-consulting">
                                        Free NFT Consulting
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/ecosystem">
                                        NFT Ecosystem
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/white-label-multi-chain-marketplace-development">
                                        Whitelabel Multi chain NFT
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/white-label-launchpad-development">
                                        White Label NFT Launchpad
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/launchpad-development">
                                        NFT Launchpad Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/cross-chain-marketplace-development">
                                        Cross-Chain NFT Marketplace
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/exchange-development">
                                        NFT Exchange Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/wallet-development">
                                        NFT Wallet Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/minting-platform-development">
                                        NFT Minting Platform Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/non-fungible-token-development">
                                        NFT Token Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/dao-enabled-platform-development">
                                        DAO Enabled NFT Platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/marketplace-with-governance-token-development">
                                        NFT Marketplace With Governance
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/celebrity-nft-marketplace-development">
                                        Celebrity NFT Marketplace
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/music-nft-marketplace-development">
                                        NFT Marketplace for Music
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/nft-art-marketplace-development">
                                        NFT Marketplace for Artist
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/sports-nft-marketplace-development">
                                        NFT Marketplace for Sports
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/physical-assets-marketplace-development">
                                        NFT for Physical assets
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/ticketing-marketplace-development">
                                        NFT Ticketing Marketplace
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/sft-development">
                                        SFT Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/nft-studio">
                                        NFT Studio
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/aggregator-marketplace-development">
                                        NFT Aggregator Marketplace
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/ethereum-nft-marketplace-development">
                                        NFT Marketplace On Ethereum
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/polygon-nft-marketplace-development">
                                        NFT Marketplace on Polygon
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/polygon-nft-marketplace-development">
                                        NFT Marketplace on BSC
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/avalanche-nft-marketplace-development">
                                        NFT Marketplace on Avalanche
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/sidechain-nft-marketplace-development">
                                        NFT Development On Sidechain
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/solana-nft-marketplace-development">
                                        NFT Marketplace On Solana
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/harmony-nft-marketplace-development">
                                        NFT Marketplace On Harmony
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/harmony-nft-marketplace-development">
                                        NFT Marketplace Like SuperRare
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/sudoswap-like-nft-marketplace-development">
                                        NFT Marketplace like Sudoswap
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/sorare-like-nft-marketplace-development">
                                        NFT Marketplace Like Sorare
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/opensea-like-nft-marketplace-development">
                                        NFT Marketplace Like OpenSea
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/solsea-like-nft-marketplace-development">
                                        NFT Marketplace like Solsea
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/rarible-like-nft-marketplace-development">
                                        NFT Marketplace Like Rarible
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/layer-2-development">
                                        NFT Layer 2 Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/phygital-marketplace-development">
                                        Phygital NFT Marketplace
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/p2e-game-development">
                                        P2E NFT Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/nft-real-estate-marketplace-development">
                                        NFT Marketplace for Real estate
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/nft-in-ecommerce">
                                        NFT in E-commerce
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/gaming-platform-development">
                                        NFT Gaming Platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/dungeon-swap-like-nft-game-development">
                                        Dungeon Swap Like NFT Game
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/my-crypto-heroes-like-nft-game-development">
                                        My Crypto Heros Like NFT Game (heroes)
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/zed-run-like-nft-game-development">
                                        Zed Run Like NFT Game
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/decentraland-like-nft-game-development">
                                        Decentraland Like NFT Game
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/nft-racing-game-development">
                                        NFT Racing Game
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/revv-racing-like-nft-game-development">
                                        Revv Racing Like NFT Game
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/casino-game-development">
                                        NFT Casino Game
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/nft-social-token-development">
                                        NFT Social Token Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/staking-platform-development">
                                        NFT Staking platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/streaming-platform-development">
                                        NFT Streaming Platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/consulting-service">
                                        NFT Consulting Service
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/listing-services">
                                        NFT Listing Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/fractional-marketplace-development">
                                        Fractional NFT Marketplace
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/shards-nft-platform-development">
                                        Shards NFT Platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/white-label-p2e-game-development">
                                        White Label P2E NFT Games
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/web2-to-web3-game-development">
                                        Web2 to Web3 Game
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/gaming-marketplace-development">
                                        NFT Game Marketplace
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/move-to-earn-platform-development">
                                        Move to Earn NFT Platform
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Marketing</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/nft">
                                        NFT Marketing Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/nft-marketplace">
                                        NFT Marketplace Marketing Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/nft-marketplace">
                                        NFT Collectibles Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/nft-collectibles">
                                        NFT Game Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/nft-telegram">
                                        NFT Telegram Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/influencer">
                                        Influencer Marketing Agency
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/nft-pr-and-media">
                                        NFT PR and Media Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/discord">
                                        Discord Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/discord-server-marketing">
                                        Discord Server Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/discord-brand">
                                        Discord Brand Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/marketing/discord-game">
                                        Discord Game Marketing
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Metaverse</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/metaverse-development-company">
                                        Metaverse Development Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/metaverse-nft-marketplace-development">
                                        Metaverse NFT Marketplace Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/healthcare-solutions-development">
                                        Metaverse Healthcare Solutions
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/nft-launchpad-development">
                                        NFT Metaverse Launchpad Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/nft-game-development">
                                        Metaverse NFT Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/art-gallery-development">
                                        Metaverse Art Gallery
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/avatar-development">
                                        Metaverse Aavtar Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/3d-space-development">
                                        Metaverse 3D Space Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/event-platform-development">
                                        Metaverse Event Platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/social-interaction-platform-development">
                                        Metaverse Platform for Social
                                        Interaction
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/metaverse/virtual-working-space-development">
                                        Metaverse Virtual Working Space
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Web 3.0</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/web3/web3-development-services">
                                        Web 3.0 Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/web3/dapps-development-services">
                                        Web3 dApp Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/web3/wallet-development">
                                        Web3 Wallet Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/web3/game-development">
                                        Web 3.0 Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/web3/marketing-services">
                                        Web 3.0 Marketing Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/web3/metamask-like-wallet-development">
                                        Web3 Metamask like Wallet Development
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>DeFi</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/defi-development-services">
                                        Decentralized Finance (DeFi) Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/pancakeswap-like-platform-development">
                                        DeFi Platform like Pancakeswap
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/dao-development">
                                        DeFi DAO Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/white-label-swap-exchange-development">
                                        Whitelabel Swap Exchange Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/pinksale-like-launchpad-development">
                                        Pinksale Like Launchpad Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/staking-platform-development">
                                        DeFi Staking Platform Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/yield-farming-development">
                                        DeFi Yield Farming Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/marketing-services">
                                        DeFi Marketing Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/application-development">
                                        DeFi Application Development Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/token-development">
                                        DeFi Token Development Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/wallet-development">
                                        DeFi Wallet Development Solution
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/synthetic-assets-development">
                                        DeFi Synthetic Assets Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/crowd-funding-platform-development">
                                        DeFi Crowdfunding platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/real-estate-platform-development">
                                        DeFi Real Estate platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/dex-development">
                                        DeFi Exchange Development Solution
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/uniswap-like-platform-development">
                                        DeFi Platform Like Uniswap
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/defi/protocol-like-yearn-finance-development">
                                        DeFi Protocol Development like yearn
                                        finance
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>ICO</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/ico/ico-development">
                                        ICO Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ico/marketing">
                                        ICO Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ico/community-marketing">
                                        ICO Community Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ico/telegram-marketing">
                                        ICO Telegram Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ico/influencer-marketing">
                                        ICO Influencer Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ico/ieo-development">
                                        IEO Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ico/eto-development">
                                        ETO Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ico/crowdfunding-platform">
                                        Crowdfunding Platform
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ico/youtube-influencer">
                                        ICO Youtube Influencer
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>STO</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/sto/development">
                                        STO Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/sto/marketing">
                                        STO Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/sto/smart-contract-development">
                                        STO Smart Contracts
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/sto/real-estate-tokenization">
                                        STO Real Estate Tokenization
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/sto/asset-tokenization">
                                        STO Asset Tokenization
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/sto/art-tokenization">
                                        STO Art Tokenization
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/sto/tokenization-platform-development">
                                        STO Tokenization Platform
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>IDO</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/ido/development">
                                        IDO Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ido/marketing">
                                        IDO Marketing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ido/white-label-ido-launchpad">
                                        White Label IDO Launchpad
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ido/ido-token-launchpad-development">
                                        IDO Token Launchpad
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ido/ido-launchpad-development-on-bsc">
                                        IDO Launchpad On BSC
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ido/ido-launchpad-development-on-ethereum">
                                        IDO Launchpad On Ethereum
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ido/ido-launchpad-development-on-multichain">
                                        IDO Launchpad In Multichain
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ido/ido-launchpad-development-on-polygon">
                                        IDO Launchpad On Polygon
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ido/igo-launchpad-development">
                                        IGO Launchpad Development
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Crypto</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/cryptocurrency-development">
                                        Cryptocurrency Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/stable-coin-development">
                                        Stable Coin Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/white-label-crypto-exchange">
                                        White label Crypto Exchange
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/cryptocurrency-exchange-development">
                                        Cryptocurrency Exchange Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/decentralized-exchange-development">
                                        Decentralized Exchange Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/p2p-exchange-development">
                                        Crypto P2P Exchange Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/crypto-wallet-development">
                                        Crypto Wallet Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/crypto-payment-development">
                                        Crypto-Payment Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/crypto-exchange-listing-services">
                                        Crypto Exchange Listing Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/cryptocurrency-exchange-like-wazirx">
                                        Cryptocurrency Exchange Like Wazirx
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/cryptocurrency-exchange-like-binance">
                                        Cryptocurrency Exchange Like Binance
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/crypto/cryptocurrency-exchange-like-coinbase">
                                        Cryptocurrency Exchange Like Coinbase
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Blockchain</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/blockchain-development-services">
                                        Blockchain Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/white-lable-blockchain-development-services">
                                        White Label Blockchain Solutions
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/custom-blockchain-development-on-avalanche">
                                        Custom Blockchain On Avalanche
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/custom-blockchain-development-on-polygon">
                                        Custom Blockchain On Polygon
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/solana-blockchain-development">
                                        Solana Blockchain Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/blockchain-and-web3-consulting">
                                        Blockchain and Web 3.0 Consulting
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/blockchain-proof-of-reserve">
                                        Blockchain Proof Of Reserve
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/carbon-credits-development">
                                        Carbon Credits Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/blockchain-for-finance">
                                        Blockchain for Finance
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/blockchain-for-pharma-industry">
                                        Blockchain for Pharma Industry
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/blockchain-protocol-implementation">
                                        Blockchain Protocol Implementation
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/evm-compatible-blockchain-and-service">
                                        EVM Compatible Blockchain and Service
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/smart-contract-development">
                                        Smart Contract Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/blockchain-dapps-development">
                                        Blockchain dApps Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blockchain/blockchain-p2p-lending-software">
                                        Blockchain P2P Lending Software
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default BlockchainUrl;
