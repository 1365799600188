/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import React from "react";

const MobileDropdownSubItm = ({ e, ind, closeNav }) => {
    return (
        <div className="px-3" key={ind}>
            <div className="flex justify-start py-1.5 items-center pl-8 ">
                {e?.image && (
                    <img
                        src={e?.image}
                        alt={e?.image}
                        className="w-5 h-5 object-contain "
                    />
                )}
                <Link
                    href={e?.href}
                    className="px-3 subMenuTitle !font-manrope"
                    onClick={() => {
                        closeNav();
                    }}
                >
                    {e?.title}
                </Link>
            </div>
        </div>
    );
};

export default MobileDropdownSubItm;
