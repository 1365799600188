export const technologiesMainItemMobile = [
    {
        id: 1,
        title: "Database",
        database: [
            {
                id: 1,
                image: "/assets/Header/Technologies/Database/Oracle.svg",
                href: "/database/oracle",
                title: "Oracle",
            },
            {
                id: 2,
                image: "/assets/Header/Technologies/Database/MongoDB.svg",
                href: "/database/mongodb",
                title: "MongoDB",
            },
            {
                id: 3,
                image: "/assets/Header/Technologies/Database/NoSQL Database.svg",
                href: "/database/nosql",
                title: "NoSQL Database",
            },
            {
                id: 4,
                image: "/assets/Header/Technologies/Database/MYSQL.svg",
                href: "/database/mysql",
                title: "MYSQL",
            },
            {
                id: 5,
                image: "/assets/Header/Technologies/Database/SQL Lite.svg",
                href: "/database/sqlite",
                title: "SQL Lite",
            },
            {
                id: 6,
                image: "/assets/Header/Technologies/Database/MariaDB.svg",
                href: "/database/mariadb",
                title: "MariaDB",
            },
            {
                id: 7,
                image: "/assets/Header/Technologies/Database/ElasticSearch.svg",
                href: "/database/elasticsearch",
                title: "ElasticSearch",
            },
            {
                id: 8,
                image: "/assets/Header/Technologies/Database/OrientDB.svg",
                href: "/database/orientdb",
                title: "OrientDB",
            },
            {
                id: 9,
                image: "/assets/Header/Technologies/Database/PostgreSQL.svg",
                href: "/database/postgresql",
                title: "PostgreSQL",
            },
            {
                id: 10,
                image: "/assets/Header/Technologies/Database/Redis Database.svg",
                href: "/database/redis-database",
                title: "Redis Database",
            },
            {
                id: 11,
                image: "/assets/Header/Technologies/Database/CouchDB.svg",
                href: "/database/couchdb",
                title: "CouchDB",
            },
            {
                id: 12,
                image: "/assets/Header/Technologies/Database/Neo4J.svg",
                href: "/database/neo4j-consulting",
                title: "Neo4J",
            },
            {
                id: 13,
                image: "/assets/Header/Technologies/Database/Amazon RDS.svg",
                href: "/database/amazon-rds",
                title: "Amazon RDS",
            },
            {
                id: 14,
                image: "/assets/Header/Technologies/Database/Amazon DynamoDB.svg",
                href: "/database/amazon-dynamodb",
                title: "Amazon DynamoDB",
            },
            {
                id: 15,
                image: "/assets/Header/Technologies/Database/Microsoft Azure SQL Database.svg",
                href: "/database/microsoft-azure-sql",
                title: "Microsoft Azure SQL Database",
            },
            {
                id: 16,
                image: "/assets/Header/Technologies/Database/SQL Server.svg",
                href: "/database/sql-server",
                title: "SQL Server",
            },
            {
                id: 17,
                image: "/assets/Header/Technologies/Database/Dynamodb development services.svg",
                href: "/database/dynamodb",
                title: "Dynamodb development services",
            },
            {
                id: 18,
                image: "/assets/Header/Technologies/Database/Firebase Development.svg",
                href: "/database/firebase",
                title: "Firebase Development",
            },
        ],
    },
    {
        id: 2,
        title: "Backend",
        backend: [
            {
                id: 1,
                image: "/assets/Header/Technologies/Backend/Laravel.svg",
                href: "/backend/laravel",
                title: "Laravel",
            },
            {
                id: 2,
                image: "/assets/Header/Technologies/Backend/CakePHP.svg",
                href: "/backend/cakephp",
                title: "CakePHP",
            },
            {
                id: 3,
                image: "/assets/Header/Technologies/Backend/CodeIgniter.svg",
                href: "/backend/codeigniter",
                title: "CodeIgniter",
            },
            {
                id: 4,
                image: "/assets/Header/Technologies/Backend/NodeJS.svg",
                href: "/backend/nodejs",
                title: "NodeJS",
            },
            {
                id: 5,
                image: "/assets/Header/Technologies/Backend/Django.svg",
                href: "/backend/django",
                title: "Django",
            },
            {
                id: 6,
                image: "/assets/Header/Technologies/Backend/Flask.svg",
                href: "/backend/flask",
                title: "Flask",
            },
            {
                id: 7,
                image: "/assets/Header/Technologies/Backend/GoLang.svg",
                href: "/backend/golang",
                title: "GoLang",
            },
            {
                id: 8,
                image: "/assets/Header/Technologies/Backend/Ruby on Rails.svg",
                href: "/backend/ruby-on-rails",
                title: "Ruby on Rails",
            },
            {
                id: 9,
                image: "/assets/Header/Technologies/Backend/NestJS.svg",
                href: "/backend/nestjs",
                title: "NestJS",
            },
            {
                id: 10,
                image: "/assets/Header/Technologies/Backend/ExpressJS.svg",
                href: "/backend/expressjs",
                title: "ExpressJS",
            },
            {
                id: 11,
                image: "/assets/Header/Technologies/Backend/MeteorJS.svg",
                href: "/backend/meteorjs",
                title: "MeteorJS",
            },
            {
                id: 12,
                image: "/assets/Header/Technologies/Backend/SpringBoot.svg",
                href: "/backend/springboot",
                title: "SpringBoot",
            },
            {
                id: 13,
                image: "/assets/Header/Technologies/Backend/PHP.svg",
                href: "/backend/php",
                title: "PHP",
            },
            {
                id: 14,
                image: "/assets/Header/Technologies/Backend/Scala.svg",
                href: "/backend/scala",
                title: "Scala",
            },
        ],
    },
    {
        id: 3,
        title: "Mobile",
        mobile: [
            {
                id: 1,
                image: "/assets/Header/Technologies/Mobile/Swift.svg",
                href: "/mobile/swift",
                title: "Swift",
            },
            {
                id: 2,
                image: "/assets/Header/Technologies/Mobile/Objective-C.svg",
                href: "/mobile/objective-c",
                title: "Objective-C",
            },
            {
                id: 3,
                image: "/assets/Header/Technologies/Mobile/Kotlin.svg",
                href: "/mobile/kotlin",
                title: "Kotlin",
            },
            {
                id: 4,
                image: "/assets/Header/Technologies/Mobile/Ionic App Development.svg",
                href: "/mobile/ionic-app",
                title: "Ionic App Development",
            },
            {
                id: 5,
                image: "/assets/Header/Technologies/Mobile/Xamarin Development.svg",
                href: "/mobile/xamarin",
                title: "Xamarin Development",
            },
            // {
            //   id: 7,
            //   image: "/assets/Header/About/Our-company.svg",
            //   href: "/",
            //   title: "React Native",
            // },
            // {
            //   id: 8,
            //   image: "/assets/Header/About/Our-company.svg",
            //   href: "/",
            //   title: "React Native",
            // },
        ],
    },
    {
        id: 4,
        title: "Frontend",
        frontend: [
            {
                id: 1,
                image: "/assets/Header/Technologies/Frontend/ReactJS.svg",
                href: "/frontend/reactjs",
                title: "ReactJS",
            },
            {
                id: 2,
                image: "/assets/Header/Technologies/Frontend/AngularJS.svg",
                href: "/frontend/angularjs",
                title: "AngularJS",
            },
            {
                id: 3,
                image: "/assets/Header/Technologies/Frontend/JavaScript.svg",
                href: "/frontend/javascript",
                title: "JavaScript",
            },
            {
                id: 4,
                image: "/assets/Header/Technologies/Frontend/VueJS.svg",
                href: "/frontend/vuejs",
                title: "VueJS",
            },
            {
                id: 5,
                image: "/assets/Header/Technologies/Frontend/Typescript.svg",
                href: "/frontend/typescript",
                title: "Typescript",
            },
            {
                id: 6,
                image: "/assets/Header/Technologies/Frontend/NextJS.svg",
                href: "/frontend/nextjs",
                title: "NextJS",
            },
            {
                id: 7,
                image: "/assets/Header/Technologies/Frontend/GatsbyJS.svg",
                href: "/frontend/gatsbyjs",
                title: "GatsbyJS",
            },
            {
                id: 8,
                image: "/assets/Header/Technologies/Frontend/ThreeJS.svg",
                href: "/frontend/threejs",
                title: "ThreeJS",
            },
            {
                id: 9,
                image: "/assets/Header/Technologies/Frontend/HTML5.svg",
                href: "/frontend/html",
                title: "HTML5",
            },
            {
                id: 10,
                image: "/assets/Header/Technologies/Frontend/CSS.svg",
                href: "/frontend/css3",
                title: "CSS",
            },
            {
                id: 11,
                image: "/assets/Header/Technologies/Frontend/Tailwind CSS.svg",
                href: "/frontend/tailwind",
                title: "Tailwind CSS",
            },
            {
                id: 12,
                image: "/assets/Header/Technologies/Frontend/BootStrap.svg",
                href: "/frontend/bootstrap",
                title: "BootStrap",
            },
            {
                id: 13,
                image: "/assets/Header/Technologies/Frontend/FabricJS.svg",
                href: "/frontend/fabricjs",
                title: "FabricJS",
            },
            {
                id: 14,
                image: "/assets/Header/Technologies/Frontend/ChartJS.svg",
                href: "/frontend/chartjs",
                title: "ChartJS",
            },
            {
                id: 15,
                image: "/assets/Header/Technologies/Frontend/PaperJS.svg",
                href: "/frontend/paperjs",
                title: "PaperJS",
            },
            {
                id: 16,
                image: "/assets/Header/Technologies/Frontend/D3JS.svg",
                href: "/frontend/d3js",
                title: "D3JS",
            },
        ],
    },
    {
        id: 5,
        title: "ui-ux",
        uiux: [
            {
                id: 1,
                image: "/assets/Header/Technologies/UXUI/Adobe Photoshop.svg",
                href: "/ui-ux/adobe-photoshop",
                title: "Adobe Photoshop",
            },
            {
                id: 2,
                image: "/assets/Header/Technologies/UXUI/Figma.svg",
                href: "/ui-ux/figma",
                title: "Figma",
            },
            {
                id: 3,
                image: "/assets/Header/Technologies/UXUI/Adobe XD.svg",
                href: "/ui-ux/adobe-xd",
                title: "Adobe XD",
            },
            {
                id: 4,
                image: "/assets/Header/Technologies/UXUI/Sketch.svg",
                href: "/ui-ux/sketch-design",
                title: "Sketch",
            },
            {
                id: 5,
                image: "/assets/Header/Technologies/UXUI/Invision.svg",
                href: "/ui-ux/invision-tool",
                title: "Invision",
            },
            {
                id: 6,
                image: "/assets/Header/Technologies/UXUI/Zeplin.svg",
                href: "/ui-ux/zeplin",
                title: "Zeplin",
            },
            {
                id: 7,
                image: "/assets/Header/Technologies/UXUI/Miro.svg",
                href: "/ui-ux/miro-design-tool",
                title: "Miro",
            },
            {
                id: 8,
                image: "/assets/Header/Technologies/UXUI/Adobe Illustrator.svg",
                href: "/ui-ux/adobe-illustrator",
                title: "Adobe Illustrator",
            },
            {
                id: 9,
                image: "/assets/Header/Technologies/UXUI/Adobe Animate.svg",
                href: "/ui-ux/adobe-animate",
                title: "Adobe Animate",
            },
            {
                id: 10,
                image: "/assets/Header/Technologies/UXUI/Adobe Express.svg",
                href: "/ui-ux/adobe-express",
                title: "Adobe Express",
            },
            {
                id: 11,
                image: "/assets/Header/Technologies/UXUI/Adobe After effect.svg",
                href: "/ui-ux/adobe-after-effect",
                title: "Adobe After effect",
            },
        ],
    },
    // {
    //   id: 6,
    //   title: "Bi & Analytics",
    //   biAnalytics: [
    //     {
    //       id: 1,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Power BI",
    //     },
    //     {
    //       id: 2,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Tableau",
    //     },
    //     {
    //       id: 3,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Big Data",
    //     },
    //     {
    //       id: 4,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Pentaho BI",
    //     },
    //     {
    //       id: 5,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Predictive Analytics",
    //     },
    //     {
    //       id: 6,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Datapine",
    //     },
    //     {
    //       id: 7,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Sisense",
    //     },
    //     {
    //       id: 8,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Yellowfin BI",
    //     },
    //     {
    //       id: 9,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Qlikview",
    //     },
    //     {
    //       id: 10,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "SAP BI",
    //     },
    //   ],
    // },
    // {
    //   id: 7,
    //   title: "Cloud",
    //   cloud: [
    //     {
    //       id: 1,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Azure",
    //     },
    //     {
    //       id: 2,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "AWS",
    //     },
    //     {
    //       id: 3,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Salesforce",
    //     },
    //     {
    //       id: 4,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Google cloud",
    //     },
    //     {
    //       id: 5,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Oracle Cloud",
    //     },
    //     {
    //       id: 6,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Microsoft Azure",
    //     },
    //     {
    //       id: 7,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "DigitalOcean",
    //     },
    //     {
    //       id: 8,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "VMware",
    //     },
    //     {
    //       id: 9,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "IBM",
    //     },
    //     {
    //       id: 10,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Citrix Systems",
    //     },
    //     {
    //       id: 11,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Microsoft Corporation",
    //     },
    //     {
    //       id: 12,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Cisco",
    //     },
    //     {
    //       id: 13,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "BMC Software",
    //     },
    //     {
    //       id: 14,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "ServerSpace",
    //     },
    //     {
    //       id: 15,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Kamatera",
    //     },
    //     {
    //       id: 16,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Rackspace",
    //     },
    //     {
    //       id: 17,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Red Hat",
    //     },
    //     {
    //       id: 18,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "SAP",
    //     },
    //     {
    //       id: 19,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Verizon Cloud",
    //     },
    //     {
    //       id: 20,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Navisite",
    //     },
    //     {
    //       id: 21,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Dropbox",
    //     },
    //   ],
    // },
    // {
    //   id: 8,
    //   title: "Trending Tech",
    //   trendingTech: [
    //     {
    //       id: 1,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "IoT",
    //     },
    //     {
    //       id: 2,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Machine Learning",
    //     },
    //     {
    //       id: 3,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Artificial Intelligence",
    //     },
    //     {
    //       id: 4,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "MEAN Stack",
    //     },
    //     {
    //       id: 5,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "DevOps",
    //     },
    //   ],
    // },
    // {
    //   id: 9,
    //   title: "AI",
    //   ai: [
    //     {
    //       id: 1,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Natural Language Generation",
    //     },
    //     {
    //       id: 2,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Speech Recognition",
    //     },
    //     {
    //       id: 3,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Machine Learning Platforms",
    //     },
    //     {
    //       id: 4,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Virtual Agents",
    //     },
    //     {
    //       id: 5,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Decision Management",
    //     },
    //     {
    //       id: 6,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "AI Optimized Hardware",
    //     },
    //     {
    //       id: 7,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Deep Learning Platforms",
    //     },
    //     {
    //       id: 8,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Biometrics",
    //     },
    //     {
    //       id: 9,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Robotic Process Automation",
    //     },
    //     {
    //       id: 10,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Text Analytics And NLP",
    //     },
    //     {
    //       id: 11,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Cyber Defence",
    //     },
    //     {
    //       id: 12,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Content Creation",
    //     },
    //     {
    //       id: 13,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Emotion Recognition",
    //     },
    //     {
    //       id: 14,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "Marketing Automation",
    //     },
    //     {
    //       id: 15,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "AI Application in E-Commerce",
    //     },
    //     {
    //       id: 16,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "AI Application in Education",
    //     },
    //     {
    //       id: 17,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "AI Application in Lifestyle",
    //     },
    //     {
    //       id: 18,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "AI Application inNavigation",
    //     },
    //     {
    //       id: 19,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "AI Application inHealthcare",
    //     },
    //     {
    //       id: 20,
    //       image: "/assets/Header/About/Our-company.svg",
    //       href: "/",
    //       title: "AI Application in Agriculture",
    //     },
    //     {
    //       id: 21,
    //       image: "/assets/Header/About/Face Recognition.svg",
    //       href: "/",
    //       title: "Face Recognition",
    //     },
    //   ],
    // },
];
