import React from "react";

const TechnologiesUrl = () => {
    return (
        <>
            <ul class="main-menu" id="top-menu-nav">
                <li class="mega-sub-menu">
                    <span>Database</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/database/oracle">
                                        Oracle development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/mongodb">
                                        mongodb development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/sqlite">
                                        sqllite development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/mysql">
                                        mysql development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/mariadb">
                                        MariaDB development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/elasticsearch">
                                        ElasticSearch development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/orientdb">
                                        OrientDB development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/postgresql">
                                        PostgreSQL development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/redis-database">
                                        Redis Database development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/couchdb">
                                        CouchDB Development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/neo4j-consulting">
                                        Neo4J consulting services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/amazon-rds">
                                        Amazon RDS development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/amazon-dynamodb">
                                        Amazon DynamoDB development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/microsoft-azure-sql">
                                        Microsoft Azure SQL services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/sql-server">
                                        SQL Server development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/dynamodb">
                                        dynamodb development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/database/firebase">
                                        Firebase Development
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Backend</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/laravel">
                                        Laravel Development company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/cakephp">
                                        CakePHP Development company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/codeigniter">
                                        CodeIgniter Development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/nodejs">
                                        NodeJS Development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/django">
                                        Django web development company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/flask">
                                        Flask development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/golang">
                                        GoLang development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/ruby-on-rails">
                                        Ruby on Rails development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/nestjs">
                                        NestJS development services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/expressjs">
                                        ExpressJS development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/meteorjs">
                                        MeteorJS development company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/springboot">
                                        SpringBoot developement
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/php">
                                        PHP development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/backend/scala">
                                        Scala development company
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Mobile</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/mobile/swift">
                                        Swift
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/mobile/objective-c">
                                        Objective - C
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/mobile/kotlin">
                                        Kotlin
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/mobile/ionic-app">
                                        Ionic App Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/mobile/xamarin">
                                        Xamarin Development
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Frontend</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/reactjs">
                                        ReactJS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/angularjs">
                                        AngularJS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/javascript">
                                        Javascript
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/vuejs">
                                        VueJS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/typescript">
                                        Typescript
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/nextjs">
                                        NextJS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/gatsbyjs">
                                        Gatsby JS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/threejs">
                                        Three JS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/html">
                                        HTML 5
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/css3">
                                        CSS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/tailwind">
                                        Tailwind CSS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/bootstrap">
                                        BootStrap
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/fabricjs">
                                        FabricJS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/chartjs">
                                        ChartJS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/paperjs">
                                        PaperJS
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/frontend/d3js">
                                        D3JS
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>UI/UX</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/adobe-photoshop">
                                        Adobe Photoshop
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/figma">
                                        Figma design services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/adobe-xd">
                                        Adobe XD
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/sketch-design">
                                        Sketch design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/Invision-tool">
                                        Invision-tool
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/zeplin">
                                        Zeplin
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/miro-design-tool">
                                        Miro
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/adobe-illustrator">
                                        Adobe Illustrator
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/adobe-animate">
                                        Adobe Animate
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/adobe-express">
                                        Adobe Express
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/ui-ux/adobe-after-effect">
                                        Adobe After effect
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default TechnologiesUrl;
