import React from "react";

const GamesUrl = () => {
    return (
        <>
            <ul class="main-menu" id="top-menu-nav">
                <li class="mega-sub-menu">
                    <span>Game Development</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/games/android">
                                        Android Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/ios">
                                        iOS Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/mmorpg">
                                        Mmorpg Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/3d">
                                        3D Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/game-testing">
                                        Game Testing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/game-porting">
                                        Game Porting
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/unity">
                                        Unity Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/unreal">
                                        Unreal Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/pc">
                                        PC Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/mobile">
                                        Mobile Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/hyper-casual">
                                        Hyper Casual Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/metaverse">
                                        Metaverse Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/web3">
                                        Web3 Game Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/gamification-services">
                                        gamification services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/nft">
                                        nft game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/c">
                                        c++ game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/nfts/gaming-platform-development">
                                        nft gaming platform development company
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/play-to-earn">
                                        play to earn game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/nft-design-services">
                                        nft design services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/ai">
                                        ai game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/augmented-reality">
                                        augmneted reality game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/buildbox">
                                        buildbox game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/console">
                                        console game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/battle-royale">
                                        Battle royale game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/education">
                                        educational game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/html5">
                                        html5 game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/social-network">
                                        social network game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/2d">
                                        2d game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/virtual-reality">
                                        virtual reality game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/game-development-outsourcing">
                                        Game Development outsourcing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/cross-platform">
                                        cross platform game development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/game-prototyping">
                                        game prototyping
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/game-liveops">
                                        game liveops services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/co-development-game-services">
                                        co development game services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/games/blockchain">
                                        blockchain game development
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Game Art</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/concept-art">
                                        Concept Art
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/casual-art">
                                        Casual Art
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/environment-design">
                                        Environment Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/character-design">
                                        Character Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/3d-character-design">
                                        3D Character Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/aaa">
                                        AAA
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/2d-art">
                                        2D Art
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/slots-games-art">
                                        Slots Games Art
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/2d-characters-design">
                                        2D Characters Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/2d-characters-design">
                                        2D Environment Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/3d-art">
                                        3D Art
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/hidden-objects">
                                        Hidden Objects
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/game-level-design">
                                        Game Level Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/game-economy-design">
                                        Game Economy Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/3d-environment-design">
                                        3D Environment Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/3d-game-modeling">
                                        3D Game Modeling
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/3d-sculpting">
                                        3D Sculpting
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/ui-ux-design">
                                        UI/UX Design
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-art/illustration-services">
                                        Illustration Services
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Animation</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/game-animation/2d">
                                        2D Animation
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-animation/3d">
                                        3D Animation
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-animation/motion-graphic">
                                        Motion Graphic Animation
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-animation/pre-production">
                                        pre-production animation services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-animation/asset-production">
                                        Asset production animation services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-animation/integrated-level-art-production">
                                        integrated level art production
                                        animation Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-animation/animation-and-visual-effects">
                                        animation and visual effects
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                {/* <li class="mega-sub-menu">
                    <span>Outstaffing</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-game-developers">
                                        Hire Game Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-unity-developers">
                                        Hire Unity Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-unreal-developers">
                                        Hire Unreal Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-3d-artists">
                                        Hire 3D Artists
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-3d-modelers">
                                        Hire 3d Modelers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-game-designers">
                                        Hire Game Designers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-character-designers">
                                        Hire Character Designers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-concept-artists">
                                        Hire Concept Artists
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-2d-artists">
                                        Hire 2D Artists
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-illustrators">
                                        Hire Illustrators
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-2d-animators">
                                        Hire 2D Animators
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/game-resources/hire-3d-animators">
                                        Hire 3D Animators
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li> */}
            </ul>
        </>
    );
};

export default GamesUrl;
