import React from "react";

const BlogUrl = () => {
    return (
        <>
            <ul class="main-menu" id="top-menu-nav">
                <li class="mega-sub-menu">
                    <span>Apps-Development</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/data-analytics-in-healthcare">
                                        How Data Analytics In Healthcare Is
                                        Reducing Cost In 2024
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-to-make-an-app-like-instagram">
                                        Build An App Like Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-to-make-an-app-like-airbnb">
                                        Build An App Like Airbnb
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-to-build-an-app-like-uber">
                                        BHow to Build An Travel App Like Uber
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Blockhain</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/blockchain-for-digital-identity-verification">
                                        Blockchains KYC Renaissance - The Future
                                        of Digital Identity
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-to-build-dapp-solana-blockchain">
                                        Build DApps on Solana Blockchain
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/create-nft-app-blockchain">
                                        The Blockchain Revolution - Powering NFT
                                        Apps Development
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/the-ultimate-smart-contract-audit-checklist">
                                        Ultimate Checklist For Smart Contract
                                        Audit
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-to-build-a-blockchain-based-real-estate-crowdfunding-platform">
                                        How to Build a Blockchain-Based Real
                                        Estate Crowdfunding Platform?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/account-abstraction-erc-4337-guide">
                                        Building a DApps on ERC-4337 & EIP-2938
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/what-is-hyperledger-and-how-its-work-in-dapps">
                                        Build DApps on Hyperledger Blockchain
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/account-abstraction-and-erc-4337">
                                        What Is Account Abstraction ERC 4337?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-to-build-dao-on-blockchain">
                                        How to Build a DAO on Blockchain In 2023
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>crypto</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/build-a-crypto-exchange-with-mern-stack-an-a-z-guide-for-developers">
                                        Build a Crypto Exchange with MERN Stack:
                                        An A-Z Guide for Developers
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/what-is-defi-crypto-wallet-and-how-to-create-your-own">
                                        Next-Level DeFi - A Closer Look At
                                        Cryptos Mobile Wallet Innovations
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-to-build-crypto-exchange-platform-like-binance">
                                        Initiating a Cryptocurrency Trading
                                        Platform Akin To Binance
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/create-a-white-label-cryptocurrency-exchange-inspired-by-kucoin">
                                        Empower Your Service-Based Business With
                                        a Customized White Label KuCoin Clone
                                        Scripts
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>DeFi</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/what-do-you-mean-by-blockchain-scalability">
                                        Tokenized Real-World Assets: How
                                        Blockchain Is Scaling DeFi Globally?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/liquidity-indicators">
                                        Liquidity Indicators - Improving Risk
                                        Management In DeFi
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/financial-freedom-with-defi">
                                        Launching Your DeFi Empire - A
                                        Step-by-Step Guide to Financial Freedom
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/build-defi-apps-using-account-abstraction-erc-4337">
                                        Build DeFi Apps Using Account
                                        Abstraction ERC 4337
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Games</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/gamification-in-education-learning">
                                        Gamification In Education Improves
                                        Learning
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/gamification-in-banking-stats-trends-and-examples">
                                        Gamification In Banking - Stats,Trends
                                        and Examples
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/ai-in-game-development-top-6-ai-tools-you-need-know">
                                        AI in Game Development: Top 6 AI Tools
                                        you Need Know
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Hiring</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-to-hire-a-2d-art-animator">
                                        How to Hire a 2D Art Animator: A
                                        Step-by-Step Guide for 2024
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/cost-to-hire-an-app-developer-in-2024">
                                        How Much does it cost to Hire an App
                                        Developer In 2024
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-to-hire-an-app-developer">
                                        How to Hire an App Developer - A Step-
                                        by-step Guide for 2024.
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>HR Management</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/hrm-software-development-how-to-build-a-perfect-hr-solution-for-your-needss">
                                        How To Build a HR Management System
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Metaverse</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/top-metaverse-development-companies">
                                        Top 10 Metaverse Development Companies
                                        of 2024
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/metaverse-nft-marketplace-development-your-guide">
                                        How To Build Metaverse NFT Marketplace?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-much-does-metaverse-development-cost-in-2024">
                                        How much does it Cost to Develop a
                                        Metaverse In 2024
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>NFT</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/creating-a-white-label-investment-platforms">
                                        Creating a White Label Investment
                                        Platforms
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/step-by-step-guide-to-becoming-a-pro-in-art-nft-marketplace-developments">
                                        Step-By-Step Guide To Becoming a Pro In
                                        Art NFT Marketplace Developments
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/introducing-avalanche-on-tanthetaa">
                                        Introducing Avalanche On Tanθ -
                                        Enterprise-Grade Nodes And Subnets
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="mega-sub-menu">
                    <span>Web3</span>
                    <ul class="mega-submenu">
                        <li class="has-subsubmenu">
                            <ul class="mega-submenu1">
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/web-3.0-transformation-of-car-rental-industry">
                                        Web 3.0 Transformation of Car Rental
                                        Industry
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/how-web3-storage-is-solving-the-challenges-of-data-privacy-and-security">
                                        Web3 Storage - Navigating
                                        Next-Generation Choice
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/web3-crypto-wallet-development-company">
                                        Top Trends In Web3 Wallet Development
                                        For Cryptocurrency
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tanthetaa.com/blog/web3-gaming-marketplace-development-an-extensive-guide">
                                        Web3 Gaming Marketplace Development : An
                                        Extensive Guide
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default BlogUrl;
